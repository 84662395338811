import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarMail from './TopbarMail';
// import TopbarLanguage from './TopbarLanguage';
// import TopbarNotification from './TopbarNotification';
import TopbarNav from './tobar_nav/TopbarNav';

const TopbarWithNavigation = () => (
  <div className="topbar topbar--navigation">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <Link className="topbar__logo" to="/" />
      </div>
      <div className="topbar__right">
        <TopbarNav />
      </div>
      <div className="topbar__right">
        {/* <TopbarNotification /> */}
        {/* <TopbarMail new /> */}
        <TopbarProfile />
      </div>
      {/* <div className="topbar__right_x">
        <TopbarLanguage />
      </div> */}
    </div>
  </div>
);

export default TopbarWithNavigation;
