import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout';

const Home = lazy(() => import('../Home'));
const AboutUs = lazy(() => import('../AboutUs'));
// const LearnMore = lazy(() => import('../LearnMore'));
// const Copyright = lazy(() => import('../Copyright'));
const LegalStatements = lazy(() => import('../LegalStatements'));
// const Terms = lazy(() => import('../Terms'));
// const TermsBible = lazy(() => import('../Termsbible'));
const VideoPlayer = lazy(() => import('../VideoPlayer'));
// const JoinTemp = lazy(() => import('../JoinTemp'));
// const MemberShips = lazy(() => import('../Memberships'));
const FormSuccess = lazy(() => import('../FormSuccess'));
const DynamicPage = lazy(() => import('../Page/DynamicPage'));

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/home" component={Home} />
        {/* <Route exact path="/worldcup" component={WorldCup} />
        <Route exact path="/worldcupen" component={WorldCupEn} />
        <Route exact path="/worldcupcn" component={WorldCupCn} />
        <Route exact path="/worldcupmulti" component={WorldCupMulti} />
        <Route exact path="/worldcupbible" component={WorldCupBible} /> */}
        <Route exact path="/about" component={AboutUs} />
        {/* <Route exact path="/learnmore" component={LearnMore} /> */}
        <Route
          exact
          path="/privacy"
          render={() => <Redirect exact to="/privacy-policy" />}
        />
        {/* <Route exact path="/copyright" component={Copyright} /> */}
        <Route exact path="/legalstatements" component={LegalStatements} />
        {/* <Route exact path="/terms" component={Terms} /> */}
        {/* <Route exact path="/termsbible" component={TermsBible} /> */}
        <Route exact path="/videoplayer" component={VideoPlayer} />
        {/* <Route exact path="/join" component={JoinTemp} /> */}
        {/* <Route exact path="/memberships" component={MemberShips} /> */}
        <Route exact path="/form-success" component={FormSuccess} />
        <Route exact path="/:pageId+" component={DynamicPage} />
        <Route path="" render={() => <Redirect exact to="/home" />} />
      </Switch>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Suspense fallback={<div />}>
        <Switch>
          {/* pages from above don't show topbar, otherwise wrap routes */}
          <Route path="/" component={wrappedRoutes} />
        </Switch>
      </Suspense>
    </main>
  </MainWrapper>
);

export default Router;
