// reducer composition for reducers related to ui

import customizer from './ui/customizerReducer';
import sidebar from './ui/sidebarReducer';
import theme from './ui/themeReducer';

export default function (state = {}, action) {
  return {
    customizer: customizer(state.customizer, action),
    sidebar: sidebar(state.sidebar, action),
    theme: theme(state.theme, action),
  };
}
