import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

/* class TopbarProfile extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/dipdip_menu.png`} alt="Menu" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title={t('footer.features')} image="dipdip_features.png" exact path="/features" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.organizers')} image="dipdip_organizers.png" exact path="/organizers" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.aboutus')} image="dipdip_about_us.png" exact path="/about" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.contact')} image="dipdip_contact.png" exact path="/contact" onClick={this.toggle} />
          </div>
        </Collapse>
      </div>
    );
  }
} */

const TopbarProfile = ({
  t,
}) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => {
    setCollapse(!collapse);
  };

  const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';
  const isCNVersion = process.env.REACT_APP_VERSION.startsWith('cn');

  return !isMaintenance && (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={() => toggle()}>
        <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/dipdip_menu.png`} alt="Menu" />
      </button>
      {collapse && <button className="topbar__back" onClick={() => toggle()} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {!isMaintenance && !isCNVersion && <TopbarMenuLink title={t('footer.features')} image="dipdip_features.png" exact path="/features" onClick={() => toggle()} />}
          {!isMaintenance && !isCNVersion && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && !isCNVersion && <TopbarMenuLink title={t('footer.organizers')} image="dipdip_organizers.png" exact path="/organizers" onClick={() => toggle()} />}
          {!isMaintenance && !isCNVersion && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.aboutus')} image="dipdip_about_us.png" exact path="/about" onClick={() => toggle()} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/dipdip_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.contact')} image="dipdip_contact.png" exact path="/contact" onClick={() => toggle()} />}
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarProfile);
