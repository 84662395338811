import {
  FETCH_PATCH_MANIFEST,
  FETCH_PATCH_MANIFEST_SUCCESS,
  FETCH_PATCH_MANIFEST_FAILURE,
} from '../actions/page/pageActions';

const initialState = {
  pageId: '',
  state: 'idle',
  manifest: null,
};
function page(state = initialState, action) {
  switch (action.type) {
    case FETCH_PATCH_MANIFEST:
      return {
        pageId: action.pageId,
        state: 'loading',
        manifest: null,
      };

    case FETCH_PATCH_MANIFEST_SUCCESS:
      if (action.pageId === state.pageId) {
        return {
          pageId: state.pageId,
          state: 'success',
          manifest: action.manifest,
        };
      }
      return state;


    case FETCH_PATCH_MANIFEST_FAILURE:
      if (action.pageId === state.pageId) {
        return {
          pageId: state.pageId,
          state: 'error',
          manifest: null,
        };
      }
      return state;


    default:
      return state;
  }
}

export default page;
